<template>
    <div class="template-content">
        <v-container>
            <v-row>
                <v-col>
                    <v-divider class="my-2" />

                    <p class="display-4">Display 4</p>
                    <p class="display-3">Display 3</p>
                    <p class="display-2">Display 2</p>
                    <p class="display-1">Display 1</p>
                    <p class="headline">Headline</p>
                    <p class="title">Title</p>
                    <p class="subtitle-1">Subtitle 1</p>
                    <p class="subtitle-2">Subtitle 2</p>
                    <p class="body-1">Body 1</p>
                    <p class="body-2">Body 2</p>
                    <p class="caption">Caption</p>
                    <p class="overline">Overline</p>

                    <v-divider class="my-2"/>

                    <p class="subtitle-2">Custom</p>
                    <v-btn color="primary">Primary</v-btn>
                    <v-btn color="secondary">Secondary</v-btn>
                    <v-btn color="accent">Accent</v-btn>
                    <v-btn color="text" dark>Text</v-btn>

                    <p class="subtitle-2 mt-4">Standard</p>
                    <v-btn color="info">Info</v-btn>
                    <v-btn color="success">Success</v-btn>
                    <v-btn color="warning">Warning</v-btn>
                    <v-btn color="error">Error</v-btn>

                    <v-divider class="my-2"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<style lang="scss">

</style>

<script>
export default {
    name: "Template",

    data: () => ({
        //
    })
};
</script>
